import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import './Navigation.css';
import logo from "../../../src/assets/Final-2.png";
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from "jwt-decode";

export default function NavigationBar() {
  const navigate = useNavigate();
  const location = useLocation();
  const [menuOpen, setMenuOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem('auth-token');
    if (token) {
        try {
            const decoded = jwtDecode(token);
            console.log('Decoded token:', decoded);
            setUser(decoded);
        } catch (error) {
            console.error('Failed to decode token:', error);
        }
    } else {
        console.log('No token found in localStorage');
    }
}, []);

const logout = ()=>{
  localStorage.removeItem('auth-token');
  setUser(null);
  navigate('/login');
}

  useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.slice(1));
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);

  useEffect(() => {
    const dropdownMenus = document.querySelectorAll('.dropdown-menu');

    dropdownMenus.forEach(menu => {
      menu.parentElement.addEventListener('mouseenter', () => {
        menu.style.display = 'block';
      });
      menu.parentElement.addEventListener('mouseleave', () => {
        menu.style.display = 'none';
      });
    });

    return () => {
      dropdownMenus.forEach(menu => {
        menu.parentElement.removeEventListener('mouseenter', () => { });
        menu.parentElement.removeEventListener('mouseleave', () => { });
      });
    };
  }, []);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > window.innerHeight * 0.05) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className={`container-fluid fixed-top px-0 wow fadeIn ${scrolled ? 'scrolled' : ''}`} data-wow-delay="0.1s">
      <div className="top-bar row gx-0 align-items-center d-none d-lg-flex header-class">
        <div className="col-lg-6 px-5 text-start">
          <small className='mininavtext'><i style={{ color: "white" }} className="fa fa-map-marker-alt me-2"></i>1st Floor, Al Harmain Plaza Near Sultana Foundation, Lethrar Road</small>
          <small className="ms-4 mininavtext"><i style={{ color: "white" }} className="fa fa-clock me-2"></i>24/7</small>
        </div>
        <div className="col-lg-6 px-5 text-end">
          <small className='mininavtext'><i style={{ color: "white" }} className="fa fa-envelope me-2"></i>info@cafevistaai.com</small>
          <small className="ms-4 mininavtext"><i style={{ color: "white" }} className="fa fa-phone-alt me-2"></i>+921375948528</small>
        </div>
      </div>

      <nav className={`navbar navbar-expand-lg navbar-light py-lg-0 px-lg-5 wow fadeIn nav-class ${scrolled ? 'scrolled' : ''}`} data-wow-delay="0.1s">
        <Link to="/home" className="navbar-brand ms-4 ms-lg-0">
          <img className='logo' src={logo} alt='Cafevist@' />
        </Link>
        <button type="button" className="navbar-toggler me-4" aria-expanded={menuOpen} onClick={toggleMenu}>
          {menuOpen ? <span className="navbar-toggler-icon close-icon">&times;</span> : <span className="navbar-toggler-icon"></span>}
        </button>
        <div className={`navbar-collapse ${menuOpen ? 'show' : isMobile ? 'collapse' : ''}`} id="navbarCollapse">
          <div className="navbar-nav ms-auto me-auto p-4 p-lg-0">
            <Link className={`nav-item nav-link custom-hover ${scrolled ? 'scrolled-navtext' : 'initial-navtext'}`} to="/home" onClick={closeMenu}>Home</Link>
            <Link className={`nav-item nav-link custom-hover ${scrolled ? 'scrolled-navtext' : 'initial-navtext'}`} to="/about" onClick={closeMenu}>About</Link>
            <div className={`nav-item dropdown megaMenuContainer ${scrolled ? 'scrolled-navtext' : 'initial-navtext'}`}>
              <Link className={`nav-link dropdown-toggle custom-hover ${scrolled ? 'scrolled-navtext' : 'initial-navtext'}`} to="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                Solutions
              </Link>
              <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                <div className='megaMenuContent'>
                  <li className="dropdown-submenu">
                    <Link className="text-dark" to="/service#softwareservices" onClick={closeMenu}>Software Applications</Link>
                    <ul className='megaMenu-subMenu'>
                      <li><Link className="dropdown-item text-dark" to="/service#softwareservices" onClick={closeMenu}>Desktop Applications</Link></li>
                      <li><Link className="dropdown-item text-dark" to="/service#softwareservices" onClick={closeMenu}>Mobile Applications</Link></li>
                      <li><Link className="dropdown-item text-dark" to="/service#softwareservices" onClick={closeMenu}>Web Applications</Link></li>
                    </ul>
                  </li>
                  <li className="dropdown-submenu">
                    <Link className="text-dark" to="/service#embeddedservices">Embedded Systems</Link>
                    <ul className="megaMenu-subMenu">
                      <li><Link className="dropdown-item text-dark" to="/service#embeddedservices">Microcontroller Programming</Link></li>
                      <li><Link className="dropdown-item text-dark" to="/service#embeddedservices">Embedded Software Development</Link></li>
                      <li><Link className="dropdown-item text-dark" to="/service#embeddedservices">IoT Applications</Link></li>
                    </ul>
                  </li>
                  <li className="dropdown-submenu">
                    <Link className="text-dark" to="/service#ecommerceservices">E-commerce</Link>
                    <ul className="megaMenu-subMenu">
                      <li><Link className="dropdown-item text-dark" to="/service#ecommerceservices">Platform Development</Link></li>
                      <li><Link className="dropdown-item text-dark" to="/service#ecommerceservices">Dropshipping Solutions</Link></li>
                      <li><Link className="dropdown-item text-dark" to="/service#ecommerceservices">E-commerce Strategy</Link></li>
                      <li><Link className="dropdown-item text-dark" to="/service#ecommerceservices">Marketing and SEO</Link></li>
                    </ul>
                  </li>
                  <li className="dropdown-submenu">
                    <Link className="text-dark" to="/service#appgamedevelopment">Apps & Game Development</Link>
                    <ul className="megaMenu-subMenu">
                      <li><Link className="dropdown-item text-dark" to="/service#appgamedevelopment">IOS Development</Link></li>
                      <li><Link className="dropdown-item text-dark" to="/service#appgamedevelopment">Andriod Development</Link></li>
                      <li><Link className="dropdown-item text-dark" to="/service#appgamedevelopment">Hybrid App Development</Link></li>
                      

                    </ul>
                  </li>
                  <li className="dropdown-submenu">
                    <Link className="text-dark" to="/service#appgamedevelopmentt">Game Development</Link>
                    <ul className="megaMenu-subMenu">
                    <li><Link className="dropdown-item text-dark" to="/service#appgamedevelopment">Unity 3D Game</Link></li>
                      <li><Link className="dropdown-item text-dark" to="/service#appgamedevelopment">Unreal Engine</Link></li>
                      <li><Link className="dropdown-item text-dark" to="/service#appgamedevelopment">2D Game Devlopment</Link></li>
                      <li><Link className="dropdown-item text-dark" to="/service#appgamedevelopment">3D Game Development</Link></li>
                      <li><Link className="dropdown-item text-dark" to="/service#appgamedevelopment">AR/VR Game Devleopment</Link></li>
                    </ul>
                  </li> 

                  <li className="dropdown-submenu">
                    <Link className="text-dark" to="/service#webapplications">Website Development</Link>
                    <ul className="megaMenu-subMenu">
                      <li><Link className="dropdown-item text-dark" to="/service#webapplications">Website Design & Development</Link></li>
                      <li><Link className="dropdown-item text-dark" to="/service#webapplications">E-commerce Websites</Link></li>
                      <li><Link className="dropdown-item text-dark" to="/service#webapplications">Content Management Systems</Link></li>
                      <li><Link className="dropdown-item text-dark" to="/service#webapplications">Web Hosting & Maintenance</Link></li>
                      <li><Link className="dropdown-item text-dark" to="/service#webapplications">API Integration</Link></li>
                    </ul>
                  </li>
                  <li className="dropdown-submenu">
                    <Link className="text-dark" to="/service#industrycategory">Industrial Solutions</Link>
                    <ul className="megaMenu-subMenu">
                      <li><Link className="dropdown-item text-dark" to="/service#industrycategory">Agriculture Tissues</Link></li>
                      <li><Link className="dropdown-item text-dark" to="/service#industrycategory">Tunnel Farming Rolls</Link></li>
                      <li><Link className="dropdown-item text-dark" to="/service#industrycategory">Construction Rolls</Link></li>
                      <li><Link className="dropdown-item text-dark" to="/service#industrycategory">Packaging Bags</Link></li>
                      <li><Link className="dropdown-item text-dark" to="/service#industrycategory">Recycle Polythene Bags</Link></li>
                      <li><Link className="dropdown-item text-dark" to="/service#industrycategory">LLDPE</Link></li>
                      <li><Link className="dropdown-item text-dark" to="/service#industrycategory">Black Mulching Sheets</Link></li>
                      <li><Link className="dropdown-item text-dark" to="/service#industrycategory">LDPE</Link></li>


                    </ul>
                  </li>
                  <li className="dropdown-submenu">
                    <Link className="text-dark" to="/service#airobotics">AI & Robotics</Link>
                    <ul className="megaMenu-subMenu">
                      <li><Link className="dropdown-item text-dark" to="/service#airobotics">Machine Learning Solutions</Link></li>
                      <li><Link className="dropdown-item text-dark" to="/service#airobotics">Deep Learning Models</Link></li>
                      <li><Link className="dropdown-item text-dark" to="/service#airobotics">AI-based Analytics</Link></li>
                      <li><Link className="dropdown-item text-dark" to="/service#airobotics">Customer Support Chatbots</Link></li>
                      <li><Link className="dropdown-item text-dark" to="/service#airobotics">Sales & Marketing Chatbots</Link></li>
                      <li><Link className="dropdown-item text-dark" to="/service#airobotics">Deep Learning Models</Link></li>
                      <li><Link className="dropdown-item text-dark" to="/service#airobotics">NLP Based Chatbots</Link></li>




                    </ul>
                  </li>
                </div>
              </ul>
            </div>
            <Link className={`nav-item nav-link custom-hover ${scrolled ? 'scrolled-navtext' : 'initial-navtext'}`} to="/contactus" onClick={closeMenu}>Contact Us</Link>
          </div>
          <div>
            {user ? (
                <div>
                    <span>{user.displayName}</span>
                    <button onClick={logout} className="btn navBarButton btn-sm   py-2 px-4 d-none d-lg-block navbutton">Logout</button>
                </div>
            ) : (
              <Link to="/contactus" className="btn navBarButton btn-sm   py-2 px-4 d-none d-lg-block navbutton">Get Free Consultaion</Link>
            )}
        </div>
        </div>
      </nav>
    </div>
  );
}
