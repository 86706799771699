import React from 'react'
import './OurTeam.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import ahsan from "../assets/Team/Ahsan.jpg";
import fahad from "../assets/Team/Fahad.jpg";
import saad from "../assets/Team/Saad.jpg"
import ammar from "../assets/Team/Ammar.jpg";
import teamlead from "../assets/Team/WebExpert.jpg";
import arzoo from "../assets/Team/arzoo.jpg";
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faTwitter, faFacebook } from '@fortawesome/free-brands-svg-icons';


const teamMembers = [
    {
        imgUrl: ahsan,
        name: 'Ahsan Javed',
        position: 'Chief Executive Officer'
    },

    {
        imgUrl: fahad,
        name: 'Fahad Razzaq',
        position: 'Chief Technical Officer'
    },
    {
        imgUrl: saad,
        name: 'Saad Ali',
        position: 'Embedded & Design Engineer'
    },
    {
        imgUrl: teamlead,
        name: 'Fakhar Alam',
        position: 'Technology Lead'
    },
    {
        imgUrl: ammar,
        name: 'Ammar Javid',
        position: 'Chief Marketing Officer'
    },

    {
        imgUrl: arzoo,
        name: 'Arzoo Niaz',
        position: 'Full Stack Developer'
    },
]

const OurTeam = () => {
    return (
        <section className='our__team'>
            <div className='container'>
                <div className='team__content'>
                    <h6 className='subtitle'>Our Team</h6>
                    <h2>
                        Join With <span className='highlight'>Our Team</span>
                    </h2>
                </div>
                <div className='team__wrapper'>
                    {
                        teamMembers.map((item, index) => (
                            <div className='team__item' key={index}>
                                <div className='team__img'>
                                    <img src={item.imgUrl} alt='' />
                                </div>
                                <div className='team__details'>
                                    <div className='team-name-icon'>
                                        <h4>{item.name}</h4>
                                        <span>
                                            <a href='https://www.linkedin.com' target='_blank' rel='noopener noreferrer' className='social-icon'>
                                                <FontAwesomeIcon icon={faLinkedin} />
                                            </a>
                                        </span>
                                    </div>
                                    <p className='description'>{item.position}</p>

                                    <div className='team__member-social'>


                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </section>
    )
}

export default OurTeam