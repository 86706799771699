import React, { useState } from 'react';
import './CertificateVerify.css';
import Final from '../../assets/Final-2.png';

const CertificateVerification = () => {
    const [certificateID, setCertificateID] = useState("");
    const [certificateDetails, setCertificateDetails] = useState(null);
    const [isVerified, setIsVerified] = useState(false);
    const [error, setError] = useState("");

    const checkCertificate = async () => {
        setError(""); // Clear previous errors
        const csrfToken = getCSRFToken();
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/certificates/${certificateID}/`, {
                method: 'GET',  // Use GET if you’re fetching by ID
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': csrfToken,  // Include the CSRF token here
                  },
            });

            if (response.ok) {
                const data = await response.json();
                setCertificateDetails(data);
                setIsVerified(true);
                setError(""); // Clear errors when successful
            } else {
                setCertificateDetails(null);
                setIsVerified(false);
                setError("Certificate not found.");
            }
        } catch (error) {
            console.error('Error fetching certificate:', error);
            setError("An error occurred while fetching the certificate.");
        }
    };

    const getCSRFToken = () => {
        const cookies = document.cookie.split(';');
        for (let cookie of cookies) {
          if (cookie.trim().startsWith('csrftoken=')) {
            return cookie.trim().substring('csrftoken='.length);
          }
        }
        return '';
      };
      

    return (
        <div className="container p-5 mainDiv">
            <header className='p-2 certHeader'>
                <img src={Final} alt="Cafevist@ Logo" className="logo" />
                <h1 className='mb-2'>Certificate Verification</h1>
            </header>

            <input
                className='certificate-input'
                value={certificateID}
                onChange={(e) => setCertificateID(e.target.value)}
                type="text"
                placeholder="Enter Certificate ID"
            />
            <button className='verify-button' onClick={checkCertificate}>Verify</button>

            <main>
                {error && <p className="error-message">{error}</p>}

                {isVerified && certificateDetails && (
                    <>
                        <div className="certificate-details mt-2">
                            <h2 className='text-center'>Certificate Details</h2>
                            <hr />
                            <p><strong>Recipient Name:</strong> {certificateDetails.recipientName}</p>
                            <hr />
                            <p><strong>Certificate ID:</strong> {certificateDetails.certificate_ID}</p>
                            <hr />
                            <p><strong>Internship Period:</strong> {certificateDetails.internship_Period}</p>
                            <hr />
                            <p><strong>Position:</strong> {certificateDetails.position}</p>
                            <hr />
                            <p><strong>Issuer:</strong> {certificateDetails.issuer}</p>
                            <hr />
                            <p><strong>Certificate Type:</strong> {certificateDetails.certificate_Type}</p>
                        </div>
                        <div className="verification-message p-1 mt-2">
                            <p>This certificate is valid and has been issued by Cafevist@.</p>
                        </div>
                        <div className="verification-date">
                            <p><strong>Verification Date:</strong> {new Date().toLocaleDateString()}</p>
                        </div>
                    </>
                )}
            </main>

            <footer className='p-2'>
                <p>For further assistance, please contact us at <a href="mailto:support@cafevistaai.com">support@cafevistaai.com</a></p>
            </footer>
        </div>
    );
};

export default CertificateVerification;
